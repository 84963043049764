import * as React from "react";
import { useStaticQuery, graphql, Script } from "gatsby";
import Contactcontent from "../../components/ContactContent";
import Layout from "../../components/layout/Layout";
import Seo from "../../components/Seo";
import { getHead } from "../../services/rankMathServices";
import parse from "html-react-parser";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const Contact: React.FC<any> = ({ data }) => {
  const contactData = data?.allWpPage?.nodes[0];
  // console.log(contactData , "Page data");
  // const seoInfo = data?.allWpPage?.nodes?.[0]?.seo?.fullHead;

  const [info, setInfo] = React.useState(null);
  // React.useEffect(() => {
  //   getHead("contact").then((data: any) => { setInfo(data.head) })
  // }, [])

  return (
    <Layout>
      {/* {info && <Seo info={info} />} */}
      <Contactcontent pageData={contactData} />
    </Layout>
  );
};

export default Contact;

export const query = graphql`
query {
  allWpPage(filter: { slug: { eq: "contact" } }) {
    nodes {
      id
      slug
      title
      content
      seo{
        fullHead
      }
      contactPageData {
        mainBanner {
          description
          bannerBackgroundImage {
            node {
              sourceUrl
              altText
            }
          }
        }
        contactFormHeading
      }
    }
  }
}
`

export const Head = (props: any) => {
  const { data } = props;
  const seoInfo = data.allWpPage.nodes[0].seo.fullHead;
  const parseHtml: any = parse(seoInfo);

  const index =
    parseHtml &&
    parseHtml?.length > 0 &&
    parseHtml.findIndex(
      (item: any) => item?.props?.type === "application/ld+json"
    );

  if (index > -1) {
    parseHtml.splice(index, 1);
  }
  return (
    <>
      {parseHtml}
      <Seo info={seoInfo} />
      
    </>
  )
}
