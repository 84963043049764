import * as Yup from "yup";
// const phoneRegExp = /^$| ^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^(\+?\d{1,2})?\d{10,12}$/;
const contactSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Please enter email"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(),
  message: Yup.string()
    .required("Please enter message")
    .min(4, "message must be at least four characters long"),
  department: Yup.string()
    // .required("Department is required.")
    .test("not-select", "Please select a department.", (value) => value !== ""),
});

export default contactSchema;
