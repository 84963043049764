import React, { FC } from 'react';

interface props {
    pageData: any;
}

const ContactBanner: FC<props> = ({ pageData }) => {
    const bannerData = pageData?.contactPageData?.mainBanner;
    return(
        <div className="about_banner_wrapper md:mt-[40px] mt-[20px]">
            {bannerData?.bannerBackgroundImage?.node.sourceUrl ?
             (<div className="about_banner_div contact-banner flex items-center w-full !bg-bg-grey lg:rounded-[21px] rounded-[12px] lg:px-[50px]  px-[15px] mb-[30px]">
                {/* <img
                    src={bannerData?.bannerBackgroundImage?.node?.sourceUrl}
                    width={500}
                    height={409}
                    className="w-full object-contain"
                    alt={
                        bannerData.bannerBackgroundImage.node.sourceUrl
                        ? bannerData?.bannerBackgroundImage?.node?.altText
                        : "Spartan Contact Banner"
                    }
                /> */}
                <div className='banner_content_div flex md:flex-row flex-col justify-between items-center'>
                    <div className='banner_description my-auto' dangerouslySetInnerHTML={{ __html: bannerData?.description }}></div>
                </div>
            </div> ) : ( <h1>No banner available</h1>)}
        </div>
    )

}

export default ContactBanner