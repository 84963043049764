interface Requester {
	name: string;
	email: string;
	phone: number;
}
interface Payload {
	subject: string;
	description: string;
	requester: Requester;
}

export const createZendeskTicket = async (payload: Payload) => {
	// const subdomain = "codeclouds7021";
	// const email = "supratim.pathak@codeclouds.co.in";
	// const apiToken = "LOhQcDiE0NMQblBJqtRBxEwEROJ8xt32GuccUjfZ";

	const subdomain = process.env.GATSBY_ZENDESK_SUBDOMAIN;
	const email = process.env.GATSBY_ZENDESK_EMAIL;
	const apiToken = process.env.GATSBY_ZENDESK_API;
	const url = `https://${subdomain}.zendesk.com/api/v2/tickets.json`;

	// Ticket data
	const ticketData = {
		ticket: payload,

		// ticket: {
		// 	subject: "THIS IS A TEST TICKET SUBJECT.",
		// 	description:
		// 		"THIS IS A TEST TICKET  Description of the ticket from supratim",
		// 	requester: {
		// 		name: "Requester Name",
		// 		email: "peussawoummauba-7188@yopmail.com",
		// 		phone: "9051567886",
		// 	},
		// },
	};

	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Basic " + btoa(`${email}/token:${apiToken}`),
			},
			body: JSON.stringify(ticketData),
		});

		if (response.ok) {
			const data = await response.json();
			return {
				success: true,
				data,
			};
		} else {
			// console.error("Error creating ticket:", response.statusText);
			return {
				success: false,
				data: false,
			};
		}
	} catch (error) {
		// console.error("Error:", error);
	}
};
